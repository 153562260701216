import {isUndefined, UyapClient} from "@/classes/UyapClient/UyapClient";
import axios, {AxiosResponse, Method} from "axios";

export interface KurumSorgulamaTalep {
    mersisNo: string,
    kurumAdi: string,
    vergiNo: string,
    detsisNo: string
}

export interface KurumSorgulamaCevap {

}

export class KurumSorgulama extends UyapClient<KurumSorgulamaTalep, KurumSorgulamaCevap> {
    getMethod(): Method {
        return "post";
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/kurum_sorgulaJSON.ajx";
    }

    getMockResponse(): string {
        return JSON.stringify([
            {
                "kisiKurumID": 192145110,
                "kurumAdi": "ZİRVE AKÜMÜLATÖR ELEKTRİK OTOMASYON SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
                "vergiNo": "8770013406",
                "ticaretSicilNo": "278",
                "ticaretSicilNoVerildigiYer": "KAYSERİ TİCARET SİCİL MÜDÜRLÜĞÜ",
                "harcDurumu": 1,
                "kamuOzel": "O",
                "sskIsyeriSicilNo": "45611",
                "mersisNo": "0998082841100011",
                "veriGelisYeri": 0,
                "merkezAdresi":
                    {
                        "ilKodu": 38,
                        "ilAdi": "KAYSERİ",
                        "ilceAdi": "KOCASİNAN",
                        "ilceKodu": 8,
                        "adres": "OYMAAĞAÇ MAH.   5068 SOKAK  No:8/C KOCASİNAN/KAYSERİ",
                        "kisiKurumID": 192145110,
                        "adresID": 205901697,
                        "adresTuru": "ADRTR00011",
                        "adresTuruAciklama": "Mersis Adresi",
                        "eklemeTarihi": "Sep 11, 2017 8:56:58 AM",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false,
                        "aktif": "E"
                    },
                "faaliyetDurumu": 1,
                "ortakBilgileri": [
                    {
                        "ortakTipi": 2,
                        "adi": "UFUK",
                        "soyadi": "DİK",
                        "unvan": "",
                        "tcKimlikNo": "13925809962"
                    },
                    {
                        "ortakTipi": 2,
                        "adi": "OSMAN",
                        "soyadi": "ERÖZ",
                        "unvan": "",
                        "tcKimlikNo": "17297420732"
                    }],
                "temsilciBilgileri": [
                    {
                        "tcKimlikNo": "13925809962",
                        "adi": "UFUK",
                        "soyadi": "DİK",
                        "temsilciTipi": 3,
                        "unvani": "",
                        "KisiTipiKodu": 0,
                        "yetkiSuresiGun": 0,
                        "yetkiSuresiAy": 0,
                        "yetkiSuresiYil": 0
                    }],
                "tacirMi": "H",
                "mersisIlKodu": 0,
                "mersisIlceKodu": 0
            }]);
    }

    parse(dataStr: string): Promise<KurumSorgulamaCevap> {
        return new Promise<KurumSorgulamaCevap>((resolve, reject) => {
            // if (!isUndefined(data.message)) {
            //     return reject(data);
            // }
            let data = JSON.parse(dataStr);
            if (!isUndefined(data.error)) {
                //{"errorCode":"YRG_TRF_10077-101", "error":"Kurum bulunamadÄ±. "}
                return reject({message: data.error, errorCode: data.errorCode});
            }
            return resolve(data);
        })
    }

}

export default new KurumSorgulama();

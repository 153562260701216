















import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import {mixins} from "vue-class-component";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    directives: {
        mask
    },
})
export default class MersisNumarasi extends Mixins(SimpleInputMixin) {

    @Prop({default:false}) required !: any;

    mask = "################";
    rules = [
        (value: any) => {
            return (!value || (value && value.length == 16) ) || "16 karakter olmalıdır.";
        },
    ];

    get isRequired():boolean{
        if(this.required || this.required == ""){
            return true;
        }
        return false;
    }

    validate() {
        this.$emit("value", this.localValue);
    }
};











import {Component, Prop, Vue} from "vue-property-decorator";
import {KisiSorgulamaCevap} from "@/classes/UyapClient/KisiSorgulama";

@Component({})
export default class UyapKisiSorgulamaCevap extends Vue {
    @Prop()
    item!: KisiSorgulamaCevap;

}

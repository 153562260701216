import {isUndefined, UyapClient} from "@/classes/UyapClient/UyapClient";
import axios, {AxiosResponse, Method} from "axios";

export interface KisiSorgulamaTalep {
    tcKimlikNo: string
}

export interface KisiSorgulamaCevap {
    olumKaydi: boolean,
    isAvukat: boolean,
    isNoter: boolean,
    isTarafUyari: boolean,
    isHakimSavci: boolean,
    mernisDegisiklikVarmi: boolean,
    mernisDegisiklikNedeni: string,
    sorguTuru: number,
    kisiKurumID: number,
    tcKimlikNo: number,
    adi: string,
    soyadi: string,
    babaAdi: string,
    anaAdi: string,
    dogumTarihiStr: string,
    cinsiyeti: string,
    aktifMernisAdresiSorgusu: boolean,
    hasOlumKaydi: boolean
}

export class KisiSorgulama extends UyapClient<KisiSorgulamaTalep, KisiSorgulamaCevap> {
    getMethod(): Method {
        return 'post';
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/kisiSorgulaJSON.ajx";
    }

    getMockResponse(): KisiSorgulamaCevap{
        return <KisiSorgulamaCevap>{
            olumKaydi: false,
            isAvukat: false,
            isNoter: false,
            isTarafUyari: false,
            isHakimSavci: false,
            mernisDegisiklikVarmi: false,
            mernisDegisiklikNedeni: "",
            sorguTuru: 0,
            kisiKurumID: 59742137,
            tcKimlikNo: 11111111110,
            adi: "A_______",
            soyadi: "D______",
            babaAdi: "B____",
            anaAdi: "R____",
            dogumTarihiStr: "17/03/1998",
            cinsiyeti: "E",
            aktifMernisAdresiSorgusu: true,
            hasOlumKaydi: false
        };
    }

    parse(dataStr: string|object|any): Promise<KisiSorgulamaCevap> {
        return new Promise((resolve, reject) => {
            if(typeof dataStr =="object"){
                return resolve(dataStr);
            }
            reject();

        })
    }

}

export default new KisiSorgulama();

















import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import {mixins} from "vue-class-component";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    directives: {
        mask
    },
})
export default class DetsisNumarasi extends Mixins(SimpleInputMixin) {

    mask = "################";
    rules = [
        (value: any) => {
            return (!value || (value && value.length == 8) ) || "8 karakter olmalıdır.";
        },
    ];

    validate() {
        this.$emit("value", this.localValue);
    }
};

import axios, {AxiosError, AxiosResponse} from "axios";

export default class ImzaciClient {

    ImzaClientAddr: string = "http://127.0.0.1:4723/";

    heartbeat(): Promise<AxiosResponse> {
        var xml = '<root><auth><token></token><hash></hash></auth><data></data></root>';
        return new Promise((s, f) => {
            axios.post(this.ImzaClientAddr + 'xml/heartbeat', {xml: xml}).then(function (data: AxiosResponse) {
                return s(data);
            }).catch(function (e: AxiosError) {
                console.log("ImcaziClient.promise.catch");
                return f(e);
            });
        });
    };

    killYourSelf() {
        return new Promise((s, f) => {
            var xml = '<root><auth><token></token><hash></hash></auth><data></data></root>';
            axios.post(this.ImzaClientAddr + 'xml/killyourself', {xml: xml}).then(function (data) {
                return s(data);
            }).catch(function (e) {
                console.log('fail kill yourself');
                return f(e);
            });
        });
    }

    login() {
        return new Promise((s, f) => {
            axios.post(this.ImzaClientAddr + 'xml/session').then(function (data) {
                return s(data);
            }).catch(function (e) {
                f(e);
            });
        });
    }

    fastLogin() {
        return new Promise((s, f) => {
            axios.post(this.ImzaClientAddr + 'xml/sessionFast').then(function (data) {
                return s(data);
            }).catch(function (e) {
                f(e);
            });
        });
    }
}

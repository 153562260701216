















































import {Component, Prop, Vue} from "vue-property-decorator";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import BasicForm from "@/layout/form/BasicForm.vue";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import ImzaciClient from "@/classes/ImzaciClient";
import {AxiosError, AxiosResponse} from "axios";
import MersisNumarasi from "@/components/inputs/MersisNumarasi.vue";
import DetsisNumarasi from "@/components/inputs/DetsisNumarasi.vue";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import KurumAdi from "@/components/inputs/KurumAdi.vue";
import KisiSorgulama, {KisiSorgulamaCevap} from "@/classes/UyapClient/KisiSorgulama";
import {UyapClient} from "@/classes/UyapClient/UyapClient";
import UyapKisiSorgulamaCevap from "@/components/eicrapro/UyapKisiSorgulamaCevap.vue";
import KurumSorgulama, { KurumSorgulamaCevap, KurumSorgulamaTalep} from "@/classes/UyapClient/KurumSorgulama";

enum SorguStates {
    form,
    sorgu,
    hata,
    sonuc
}

@Component({
    components: {
        UyapKisiSorgulamaCevap,
        KurumAdi,
        VergiNumarasi, DetsisNumarasi, MersisNumarasi, TcKimlikNumarasi, BasicForm, FormDialogButton
    }
})
export default class KisiKurumSorgula extends Vue {
    SorguStates = SorguStates;
    kisiSorgulaState: SorguStates = SorguStates.form;
    kisiSorgulamaCevap!: KisiSorgulamaCevap;
    kurumSorgulaState = "form";
    tckimlikno = "11111111110";
    err: any = "";
    scc: any = "";

    kurumSorgulamaTalep: KurumSorgulamaTalep=<KurumSorgulamaTalep>{};
    kurumSorgulamaCevap!: KurumSorgulamaCevap;


    kisiSorgulaReset() {
        this.kisiSorgulaState = SorguStates.form;
        delete this.kisiSorgulamaCevap;
    }

    kisiSorgula() {
        this.kisiSorgulaState = SorguStates.sorgu;

        KisiSorgulama.run({tcKimlikNo: this.tckimlikno}).then((kisiSorgulamaCevap: KisiSorgulamaCevap) => {
            this.kisiSorgulamaCevap = kisiSorgulamaCevap;
            this.kisiSorgulaState = SorguStates.sonuc;
        }).catch((err: Error) => {
            console.log(err);
            this.kisiSorgulaState = SorguStates.hata;
        });

    }

    kurumSorgula() {
        KurumSorgulama.run(this.kurumSorgulamaTalep).then((cevap: KurumSorgulamaCevap) => {
            this.kurumSorgulamaCevap = cevap;
        });
    }

    heartbeat() {
        let client = new ImzaciClient();
        client.heartbeat().then((s: AxiosResponse) => {
            this.scc = s.data;
        }).catch((err: AxiosError) => {
            this.err = err.response?.data;
        });
    }


}












import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({})
export default class Tutar extends Mixins(SimpleInputMixin) {

}
